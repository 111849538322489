.homeContainer {
    padding: 20px;
    text-align: center;
}

h1 {
    color: #333;
    font-size: 2.5rem;
}

p {
    color: #666;
    font-size: 1.2rem;
}

body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1.6;
    background-color: #f9f9f9;
    color: #333;
}

section {
    padding: 50px 20px;
    margin: 20px auto;
    max-width: 900px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1, h2, h3 {
    color: #0056b3;
    margin-bottom: 20px;
}

p {
    margin-bottom: 15px;
}

a {
    color: #007bff;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}

.language-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.language-card {
    background: #eef2f7;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.language-card img {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
}

.language-card h3 {
    margin: 10px 0;
    font-size: 1.2em;
    color: #333;
}


.timeline {
    position: relative;
    margin: 20px 0;
    padding: 0;
    list-style: none;
}

.timeline::before {
    content: '';
    position: absolute;
    left: 50%;
    width: 2px;
    height: 100%;
    background: #ccc;
    transform: translateX(-50%);
}

.timeline-item {
    position: relative;
    margin: 20px 0;
    padding-left: 60px;
    text-align: left;
}

.timeline-item:nth-child(odd) {
    text-align: right;
    padding-left: 0;
    padding-right: 60px;
}

.timeline-item::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 50%;
    width: 15px;
    height: 15px;
    background: #0056b3;
    border-radius: 50%;
    transform: translateX(-50%);
}

.progress-container {
    background: #f3f3f3;
    border-radius: 20px;
    width: 100%;
    margin: 20px 0;
    overflow: hidden;
    box-shadow: inset 0 1px 3px rgba(0,0,0,0.1);
}

.progress-bar {
    height: 20px;
    background: linear-gradient(90deg, #007bff, #00d4ff);
    width: 70%; /* Update this width dynamically with JS */
    transition: width 0.4s ease;
    border-radius: 20px;
}
.timeline {
    display: flex;
    justify-content: center; /* Centers the timeline horizontally */
    align-items: center; /* Centers the content vertically (optional) */
    flex-direction: column; /* Ensures items stack vertically */
    padding: 20px;
    margin: 0 auto; /* Centers the section on the page */
    text-align: center; /* Aligns text to the center */
    max-width: 800px; /* Optional: Adds a fixed width to the section */
}

.timelineList {
    position: relative;
    list-style: none;
    padding: 0;
}

.timelineList::before {
    content: '';
    position: absolute;
    left: 50%;
    width: 2px;
    height: 100%;
    background: #ccc;
    transform: translateX(-50%);
}

.timelineItem {
    position: relative;
    margin: 20px 0;
    text-align: left;
    padding-left: 60px;
}

.timelineItem:nth-child(odd) {
    text-align: right;
    padding-left: 0;
    padding-right: 60px;
}

.timelineItem::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 50%;
    width: 15px;
    height: 15px;
    background: #0056b3;
    border-radius: 50%;
    transform: translateX(-50%);
}

.timelineDate {
    font-weight: bold;
    color: #333;
}
