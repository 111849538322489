.homeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #f0f0f0; /* Example background color */
}


.aboutContainer {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    background-color: #ffffff; /* Example background color */

}

h1 {
    color: #333;
    font-size: 2.5rem;
}

p {
    color: #666;
    font-size: 1rem;
    line-height: 1.6;
}
