/* src/pages/NotePage/NotePage.module.css */
.noteContainer {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.noteContainer h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.noteContainer p {
    line-height: 1.6;
}
