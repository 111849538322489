.navigationContainer {
    text-align: center;
    margin-top: 20px;

}


.navLink {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 10px 10px;
    text-decoration: none;
    border-radius: 50px;
    transition: background-color 0.3s;
    margin-right: 10px; /* Add space between the buttons */

}

.navLink:hover {
    background-color: #f700ff;
}
