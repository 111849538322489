
button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  button:active {
    background-color: #00408f;
  }
  