/* src/components/NoteViewer/NoteViewer.module.css */
.noteContainer {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #f9f9f9; /* Light background for contrast */
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.noteContainer h1 {
    font-size: 28px;
    color: #333; /* Darker color for the title */
}

.noteContainer p {
    line-height: 1.6; /* Improved readability */
    color: #555; /* Slightly lighter text color */
}

.noteContainer a {
    color: #007bff; /* Link color */
    text-decoration: none; /* Remove underline */
}

.noteContainer a:hover {
    text-decoration: underline; /* Underline on hover */
}